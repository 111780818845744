
const scrollTime = 50 // ms
const scrollRate = 5

export const scrollTo = (id:string) => {
    const elem = document.querySelector(`#${id}`)
    if(!elem) return
    
    const box = elem.getBoundingClientRect()
    const currScroll = document.documentElement.scrollTop

    const direction = Math.abs(box.top)/box.top

    let counter = 1

    const scrollSpeed = (box.top - currScroll)/(scrollTime/scrollRate)

    const doScroll = setInterval(() =>{
        window.scroll(0, currScroll + counter * scrollSpeed)
        counter ++
        const newBox = elem.getBoundingClientRect()
        const measure = newBox.top - 85
        const arrived = (Math.abs(measure)/measure) != direction
        if(arrived){
            clearInterval(doScroll)
        }
    }, scrollRate)

}