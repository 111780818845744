
import Masonry from 'masonry-layout';
import { defineComponent, watch, onMounted, ref, nextTick } from 'vue'
import imagesLoaded from 'imagesloaded'
import {isMobile} from '@/isMobile'
import windowWidth from '@/windowWidth'


export default defineComponent({
  name: "Isotope",
  props:{
      initialize:{
        default:0
      },
      columnWidth:{
        default:0
      },
      fitWidth:{
        default:false
      },
      percentPosition:{
        default:false
      },
      gutter:{
        default:0
      },
      activated:{
        default:true
      },
      _style:{
        default:''
      },
      fixWidth:{
          default:false
      }
  },
  setup(props, {emit}){
    // init this hook
    const thiselement = ref(document.createElement('div'))
    const isoContainer = ref(document.createElement('div'))

    const timeStamp = String(Math.round(Date.now()*Math.random()))
    const itemClass = `grid-item${timeStamp}`

    const initializeLock = ref(0)
    const initializePending = ref(0)


    onMounted(()=>{

        const grid = thiselement.value ;
        const gridItems = Array.from(grid.children) as HTMLElement[]

        const oldWidth = ref(0)

        const masonry = ref({} as any)

        const adjustWidth = () => {
            if(isMobile())return
            if(windowWidth.value <800) return
            if(props.fixWidth)return
            
            if(!isoContainer.value)return
            if(!thiselement.value)return
            const gridItem = Array.from(thiselement.value.children)[0] as HTMLElement
            try{
                const newItemWidth = gridItem.getBoundingClientRect().width;
                const newGridWidth = thiselement.value.getBoundingClientRect().width;
                const newTargetWidth = newGridWidth - (newGridWidth % newItemWidth) + 20
    
                isoContainer.value.style.width = `${newTargetWidth}px`
                thiselement.value.style.width = `${newTargetWidth}px`
                if(Math.abs(newTargetWidth - oldWidth.value)>5){
                    oldWidth.value = newTargetWidth
                    emit('newWidth', newTargetWidth);
                }
            }catch(err){
                console.log()
            }
        }

        const oldItemsInRow = ref(0)

        const widthChangeEnough = () => {
            if(!isoContainer.value)return  false
            if(!thiselement.value)return false
            if(!oldItemsInRow.value)return true

            const gridItem = Array.from(thiselement.value.children)[0] as HTMLElement
            if(!gridItem){
                return false
            }

            const newItemWidth = gridItem.getBoundingClientRect().width;
            const newGridWidth = isoContainer.value.getBoundingClientRect().width;
            const newItemsInRow = Math.floor(newGridWidth/newItemWidth)

            if(newItemsInRow != oldItemsInRow.value){
                oldItemsInRow.value = newItemsInRow
                return true
            }else{
                return false
            }
        }

        setInterval(() => {
            if(!initializeLock.value){
                // adjustWidth()
            }
        }, 200);

        const initialize = () => {
            if(!props.activated){
                return
            }
            gridItems.forEach(elem => {
                elem.classList.add(itemClass)
            })
            const createMasonry = () => {
                masonry.value = new Masonry( grid, {
                    itemSelector: '.'+itemClass,
                    columnWidth: props.columnWidth,
                    percentPosition: props.percentPosition,
                    gutter:props.gutter,
                    fitWidth:props.fitWidth,
                });
                nextTick(()=>{
                    isoRefresh()
                })
            }
            
            imagesLoaded(grid, createMasonry)

        }

        const isoRefresh = () => {
            initializeLock.value = 1
            nextTick(()=>{
                if(!isoContainer.value){
                    initializeLock.value = 0
                    return
                }
                isoContainer.value.style.width = `100%`
                nextTick(()=>{
                    if(!thiselement.value){
                        initializeLock.value = 0
                        return
                    }
                    if(!widthChangeEnough()){
                        initializeLock.value = 0
                        return
                    }
                    thiselement.value.style.width = `100%`
                    nextTick(()=>{
                        if(!masonry.value || !masonry.value.layout){
                            initializeLock.value = 0
                            return
                        }
                        masonry.value.layout();
                        nextTick(()=>{
                            adjustWidth()
                            setTimeout(()=>{
                                initializeLock.value = 0
                                if(initializePending.value == 1){
                                    initializePending.value = 0
                                    isoRefresh()
                                }
                            }, 500)
                        })
                    })
                })
            })
        }
        

        initialize()
        
        watch(()=>props.initialize, ()=>{
            nextTick(()=>{
                if(initializeLock.value == 1){
                    initializePending.value = 1
                }if(!masonry.value|| !masonry.value.layout){
                    initialize()
                }else{
                    isoRefresh()
                }
            })
        })
    })

    return{
      thiselement,
      isoContainer
    }
  },
})
