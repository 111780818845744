import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({iso_container:_ctx.activated}),
    style: _normalizeStyle(_ctx._style),
    ref: "isoContainer"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({thiselement:_ctx.activated}),
      ref: "thiselement",
      style: _normalizeStyle(_ctx._style)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ], 6))
}